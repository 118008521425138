.wrap-container[data-v-40d16cae] {
  width: 100%;
  height: 100%;
}
.wrap-container .flow-title[data-v-40d16cae] {
    padding: 0px 10px;
    font-size: var(--rootFontSize3);
    font-weight: 700;
}
.wrap-container .wrap-main[data-v-40d16cae] {
    width: 100%;
    height: calc(100% - 60px);
    padding: 0px;
}
.wrap-container .wrap-main .form-designer-container[data-v-40d16cae] {
      width: 100%;
      height: 100%;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.change-button[data-v-40d16cae] {
  position: fixed;
  min-width: 180px;
  top: 20px;
  left: 24%;
  color: white;
  font-size: 16px;
}
.change-button span[data-v-40d16cae] {
    cursor: pointer;
    margin-left: 4.5vw;
}
